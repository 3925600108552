<template>
  <div>
    <header class="sticky top-0 z-10 bg-white shadow-md">
      <nav
        class="mx-auto flex max-w-6xl items-center justify-between p-3 lg:px-7"
        aria-label="Global">
        <div class="flex lg:flex-1">
          <NuxtLink to="/">
            <span class="sr-only">PadelCast</span>
            <NuxtImg
              preload
              class="h-14 w-auto"
              src="/images/logo.png"
              alt="PadelCast"
              height="100" />
          </NuxtLink>
        </div>

        <div v-if="isLoggedIn" class="hidden items-center lg:flex lg:flex-1">
          <NuxtLink
            v-for="item in menu"
            :key="item.name"
            :to="item.href"
            class="mx-3 flex items-center gap-x-6 rounded-lg p-2 font-secondary text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            <div
              class="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <component
                :is="item.icon"
                class="h-5 w-5 text-airforce group-hover:text-celestial"
                aria-hidden="true" />
            </div>
            {{ item.name }}
          </NuxtLink>
          <div class="mx-3 h-5 self-center border-l border-gray-200" />
          <NuxtLink
            to="/profile/settings"
            class="mx-3 flex items-center gap-x-6 rounded-lg p-2 font-secondary text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
            <div
              class="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
              <AdjustmentsHorizontalIcon
                class="h-5 w-5 text-airforce group-hover:text-celestial"
                aria-hidden="true" />
            </div>
            Impostazioni
          </NuxtLink>
        </div>

        <div class="flex lg:hidden">
          <button
            type="button"
            class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-700"
            @click="mobileMenuOpen = true">
            <Bars3Icon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        <div class="hidden space-x-4 lg:flex lg:flex-1 lg:justify-end">
          <button
            v-if="!isLoggedIn"
            class="inline-flex items-center rounded-md border border-transparent bg-celestial px-4 py-2 font-title text-xl text-white shadow-sm hover:bg-celestialsec"
            @click="handleSignIn">
            LOGIN
          </button>
          <button
            v-else
            class="inline-flex items-center rounded-md border border-transparent bg-crayola px-4 py-2 font-title text-xl text-white shadow-sm hover:bg-tawny"
            @click="handleSignOut">
            LOGOUT
          </button>
        </div>
      </nav>

      <!-- Mobile menu -->
      <Dialog
        as="div"
        class="lg:hidden"
        :open="mobileMenuOpen"
        @close="mobileMenuOpen = false">
        <div class="fixed inset-0 z-20" />
        <DialogPanel
          class="fixed inset-y-0 right-0 z-20 flex w-full flex-col justify-between overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="p-5">
            <div class="flex items-center justify-between">
              <div class="block sm:invisible">
                <NuxtLink to="/">
                  <NuxtImg
                    preload
                    class="h-14 w-auto"
                    src="/images/logo.png"
                    alt="PadelCast"
                    height="100" />
                </NuxtLink>
              </div>
              <button
                type="button"
                class="-m-2 rounded-md p-2 text-gray-700"
                @click="mobileMenuOpen = false">
                <span class="sr-only">Chiudi menu</span>
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-5 flow-root">
              <div class="-my-5 divide-y divide-gray-500/10">
                <div v-if="isLoggedIn" class="space-y-2 py-5">
                  <NuxtLink
                    v-for="item in menu"
                    :key="item.name"
                    :to="item.href"
                    class="group -mx-3 flex items-center gap-x-6 rounded-lg p-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    @click="mobileMenuOpen = false">
                    <div
                      class="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <component
                        :is="item.icon"
                        class="h-5 w-5 text-airforce group-hover:text-celestial"
                        aria-hidden="true" />
                    </div>
                    {{ item.name }}
                  </NuxtLink>
                </div>
              </div>
            </div>
            <hr class="my-5" />
            <div class="space-y-2">
              <NuxtLink
                v-if="isLoggedIn"
                to="/profile/settings"
                class="group -mx-3 flex items-center gap-x-6 rounded-lg p-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                @click="mobileMenuOpen = false">
                <div
                  class="flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <AdjustmentsHorizontalIcon
                    class="h-5 w-5 text-airforce group-hover:text-celestial"
                    aria-hidden="true" />
                </div>
                Impostazioni
              </NuxtLink>
            </div>
          </div>
          <!-- Fixed bottom layout section -->
          <div
            v-if="isLoggedIn"
            class="sticky bottom-0 grid divide-x divide-gray-900/5 bg-gray-50 text-center">
            <button
              class="bg-crayola p-2 font-title text-2xl text-white transition-colors duration-150 hover:bg-tawny"
              @click="handleSignOut">
              LOGOUT
            </button>
          </div>
          <div
            v-else
            class="sticky bottom-0 grid divide-x divide-gray-900/5 bg-gray-50 text-center">
            <button
              class="bg-celestial p-2 font-title text-2xl text-white transition-colors duration-150 hover:bg-celestialsec"
              @click="handleSignIn">
              LOGIN
            </button>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    <slot name="navbar-end" />

    <main
      class="min-h-screen bg-white bg-[radial-gradient(60%_120%_at_50%_50%,hsla(0,0%,100%,0)_0,#DBEEFA_100%)]">
      <div class="mx-auto max-w-6xl">
        <slot />
      </div>
    </main>

    <footer class="border-t-2 border-celestialsec bg-gray-100">
      <div
        class="mx-auto flex max-w-7xl flex-col items-center justify-center px-6 py-6 lg:px-8">
        <div class="flex justify-center space-x-6">
          <a
            v-for="item in socials"
            :key="item.name"
            :href="item.href"
            class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <div class="flex justify-center space-x-6">
          <a
            class="cursor-pointer text-center text-xs leading-5 text-gray-500 hover:text-gray-600"
            @click="handleContactOpen"
            >Contattaci</a
          >
          <NuxtLink
            to="/privacy"
            class="text-center text-xs leading-5 text-gray-500 hover:text-gray-600"
            >Privacy</NuxtLink
          >
        </div>
        <p class="pt-4 text-center text-xs leading-5 text-gray-500">
          &copy; {{ new Date().getFullYear() }} PadelCast
        </p>
      </div>
    </footer>

    <DialogsContact
      :is-open="isContactOpen"
      @close="handleContactClose"
      @confirm="handleContactConfirm" />

    <!-- TODO: this should be a component -->
    <div
      v-if="isLoggedIn && !isApproved"
      class="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div
        class="pointer-events-auto flex items-center justify-between gap-x-6 bg-crayola px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <p class="text-base leading-6 text-white">
          <strong class="font-semibold">Account non attivo</strong
          ><svg
            viewBox="0 0 2 2"
            class="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true">
            <circle cx="1" cy="1" r="1" /></svg
          >Contatta lo staff per attivare il tuo account
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/vue/24/outline'
import { IconsTennisBall, IconsCourts } from '#components'

useHead({
  bodyAttrs: {
    class: 'h-full',
  },
  htmlAttrs: {
    class: 'h-full bg-white',
  },
})

const menu = [
  {
    name: 'Campi',
    description: 'Scegli su quale campo giocare a Padel',
    href: '/courts',
    icon: IconsCourts,
  },
  {
    name: 'Partite',
    description: 'Registra e gestisci le tue partite di Padel',
    href: '/matches',
    icon: IconsTennisBall,
  },
  // {
  //   name: 'Giocatori',
  //   description: 'Conosci altri appassionati di Padel',
  //   href: '/players',
  //   icon: UserGroupIcon,
  // },
]

const { status, signIn, signOut } = useAuth()

const { data: authData } = useAuth()

const isLoggedIn = computed(() => status.value === 'authenticated')

const isApproved = computed(() => authData.value?.user?.isApproved)

const mobileMenuOpen = ref(false)

const handleSignIn = async () => {
  mobileMenuOpen.value = false
  await signIn()
}

const handleSignOut = async () => {
  mobileMenuOpen.value = false
  await signOut()
}

const isContactOpen = ref(false)

const handleContactClose = () => {
  isContactOpen.value = false
}

const handleContactOpen = () => {
  isContactOpen.value = true
}

const handleContactConfirm = async (message: Ref<string>) => {
  await $fetch('/api/contact', {
    method: 'POST',
    body: { message: message.value },
  })

  // TODO: handle errors

  isContactOpen.value = false
}

const socials = []
// const socials = [
//   {
//     name: 'Facebook',
//     href: '#',
//     icon:
//   },
// ]
</script>

<style></style>
