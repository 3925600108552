<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle
                as="h3"
                class="font-secondary text-lg font-medium leading-6 text-gray-900">
                Contattaci
              </DialogTitle>
              <div class="mt-1">
                <p class="text-sm text-gray-500">
                  Se hai domande o commenti, scrivici un messaggio!
                </p>
              </div>
              <div class="mt-4">
                <textarea
                  ref="messageTextarea"
                  rows="4"
                  :minlength="minLength"
                  :maxlength="maxLength"
                  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-celestial"
                  placeholder="Il tuo messaggio..." />
              </div>
              <div class="mt-5 flex justify-end space-x-2">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                  @click="close">
                  Annulla
                </button>
                <button
                  type="submit"
                  class="inline-flex justify-center rounded-md border border-transparent bg-celestial px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-celestialsec focus:outline-none"
                  @click="confirm">
                  Conferma
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

defineProps({
  isOpen: Boolean,
  minLength: {
    type: Number,
    default: 10,
  },
  maxLength: {
    type: Number,
    default: 1000,
  },
})

const messageTextarea = ref('')

const emits = defineEmits(['close', 'confirm'])

const close = () => {
  emits('close')
}

const confirm = () => {
  emits('confirm', messageTextarea.value)
}
</script>
